[v-cloak]{
	display: none;
}

.fade-transition {
	transition: opacity 1s;
}
.fade-enter, .fade-leave {
	opacity: 0;
}

@import "vuetable";