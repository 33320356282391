.no-content-box {
	border: 2px dashed gray;
	height: 75px;
	font-size: 1.5em;
}

.panel-list-list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.panel-list-list > li {
	padding: 5px 10px;
	border: dashed 1px gray;
	margin: 5px 0;
	box-shadow: 0 0 5px 0 black;
	position: relative;
}

.panel-list-list > li > .btn-group {
	position: absolute;
	right: -1px;
	top: -2px;
}