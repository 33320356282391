.sidebar {
	position: absolute;
	left: 0;
	top: 0;
	background-color: white;
	height: 100%;
	width: 250px;

	color: #006;

	a {
		text-decoration: none;
	}
	.sidebar-header {
		position: relative;

		h4 {
			margin: 0;
			padding: 11px;
		}

		button {
			position: absolute;
			display: none;
			top: 50%;
			transform: translateY(-50%);
			right: 5%;

			i {
				transition: rotate 1s;
			}
		}
	}
}

.sidebar-main-content {
	padding-top: 30px;
}


@media (max-width: $grid-float-breakpoint) {
	.sidebar {
		width: 100%;
		height: auto;

		.list-group:last-of-type {
			margin-bottom: 0;
		}
		.sidebar-header {
			button {
				display: inline;
			}
		}

		> .sidebar-content {
			height: 0;
			overflow-y: hidden;
		}

		&.open {
			> .sidebar-content {
				height: 100%;
			}

			.sidebar-header {
				button > i {
					transform: rotate(-90deg);
				}
			}
		}
	}
}

@media (min-width: $grid-float-breakpoint) {
	.sidebar-main-content {
		padding: 30px 31%;
	}
}