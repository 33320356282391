.flex-center-container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;

	&>.row {
		width: 100%;
	}
}

.flex-center-horizontal-container {
	display: flex;
	justify-content: center;
	height: 100%;
	width: 100%;


	&>.row {
		width: 100%;
	}
}

@media (min-width: $grid-float-breakpoint) {
	.row-equal-panels {

		display: flex;

		& > .col-xs-12 > .panel {
			height: 93%;
			margin-bottom: 10px;
		}
	}
}
