.navbar-brand > img {
	max-height: 75px;
	max-width: 75px;
	display: inline-block;
}

.navbar.navbar-default {
	background-color: $navbar-base;
	background: $navbar-deafult-gradient;

	.profile-frame {
		padding-bottom: 10px;
		padding-top:  10px;
		.profile-pic {
			height: $navbar-height * 0.7;
		}
	}

}