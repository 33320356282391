#app {
	height: 100%;
	width: 100%;
}

.page-wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100%;
}
@media (min-width: $grid-float-breakpoint) {
	.page-wrapper {
		height: 100%;
	}
}

.Whoops .panel {
	z-index: 100002;
}

.inactive {
	opacity: 0.4;
}

.mt-1 {
	margin-top: 1em;
}


.panel-primary {
	> .panel-heading {
		a {
			color: white;
		}
	}
}

pre {
	white-space: pre-wrap;
	word-wrap: normal;
}

video {
	background-color: white !important;
}