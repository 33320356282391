.content {
	flex: 1;
	position: relative;
}


.content > div {
	height: 100%;
	padding-bottom: 20px;
	padding-top: 20px;
}
