
// Body
$body-bg: #f6f6f6;


// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #000066;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger:  #bf5329;

// Typography
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #333333;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

$link-hover-color: #a11f1e;
$navbar-height: 70px;
$navbar-padding-vertical: 24px;
$navbar-default-brand-color: #777;
$navbar-default-link-active-bg: $brand-primary;
$navbar-default-link-hover-bg: #eeeeee;
$navbar-margin-bottom: 0px;