.topbar {
	height: $topbar-height;
	display: flex;
	align-items: center;
	position: relative;

	.profile-pic {
		height: $topbar-height * 0.7;
	}

	&.test {
		height: $topbar-height * 2;
		position: fixed;
		width: 100%;
		z-index: 100;

		.profile-pic {
			height: $topbar-height;
			margin-bottom: $topbar-height * 0.1;
		}

	}

	> * {
		margin: 0 0 0 15px;
	}

	&.topbar-default {
		background-color: $topbar-color;
		background: $topbar-gradient;

		#nav-btn {
			background-color: $topbar-menu-color;
			color: $topbar-font-color;
		}

		#nav-btn:hover {
			background-color: darken($topbar-menu-color, 5%);
		}

	}

	> #nav-btn {
		border-radius: 3px;
		border: none;
		padding: 4px 12px;
	}

	> .profile-container {
		margin-left: auto;
		position: relative;
		padding-right: 10px;

		> .dropdown-menu {
			left: -100px;
			display: block;
			overflow: hidden;
			transition: all 0.5s;
		}

	}

	> .profile-container:not(.open) {
		> .dropdown-menu {
			opacity: 0;
			max-height: 0;
		}
	}
	.flex-center-container {
		padding-top: 30px;
	}
	.navbar-header {
		height: 60px;
		margin-bottom: 2px;
		margin-left: 50px;
		white-space: nowrap;
		transition: width 0.6s;
		transition-timing-function: ease-out;
		transition-delay: 0.1s;
	}

	.navbar-brand {
		padding: 14px 0px;
		color: $topbar-font-color;
	}
	.hide-small-nav {
		margin-left: 5px;
	}

	.progress {
		width: 500px;
		.progress-bar {
			background-color: $topbar-font-color;
		}
	}

	.timer-box {
		text-align: center;
		margin-right: 50px;

		& > span {
			background-color: $brand-primary;
			border: 1px solid darken($brand-primary, 5%);
			border-radius: 4px;
			box-shadow: 0 2px 3px 0 rgba(255, 255, 255, .2) inset, 0 2px 2px 0 rgba(0, 0, 0, .1);
			color: #fff;
			margin: 2px;
			font-size: 3.5em;
			padding: 5px;

		}
	}

	@media (min-width: $grid-float-breakpoint) {
		> .profile-container {
			padding-right: 50px;
		}
	}

	@media (max-width: $grid-float-breakpoint) {
		> .breadcrumb {
			z-index: 10000;
		}

		> .breadcrumb:not(:hover) {
			> li:not(:first-child) {
				display: none;
			}
		}

		> .breadcrumb:hover {
			position: absolute;
			top: 20%;
			left: 51px;
			display: flex;
			flex-direction: column;
			align-items: center;
			box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
		}

		.progress {
			width: 200px;
		}

		.hide-small-nav {
			display: none;
		}
	}

}