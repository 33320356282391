.test-resource {
	padding: 20px;
	max-width: 500px;
}

.video {
	max-height: 600px;
}

.test-video {
	height: 500px;
	width: 1180px;
}

.test-text {
	margin-bottom: 40px;
}

.radio-label-vertical-wrapper {
	padding-bottom: 13px;
	position: relative;
	margin-bottom: 20px;
}

.radio-label-vertical {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	padding: 0 20px;
	text-align: center;
	height: 1em;
	white-space: nowrap;

	&.scale-9 {
		width: 10%;
	}

	&.scale-7 {
		width: 12%;
	}

	&.scale-5 {
		width: 17%;
	}

	input {
		position: absolute;
		top: 28px;
		left: 50%;
		margin-left: -6px;
		display: block;
		cursor: pointer;
		transform: scale(1.4);
	}
}

textarea {
	resize: none;
}

@keyframes flash {
	0% , 100% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
}

.flash {
	animation-name: flash;
	animation-duration: 2s;
	animation-iteration-count: infinite;
}

.topbar.test + .content {
	margin-top: $topbar-height * 2;
}
