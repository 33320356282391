.account-settings.panel {
  margin-top: 14px!important;

  .panel-body .row {
    text-align: center;
    div {
      margin: 10px auto;
      display: inline-block;
      float: none;
      width: 100%;
    }
  } 
}

.free-trial-switch .col-md-6 {
  padding-left: 0;
  padding-right: 0;
}

.free-trial-switch .col-md-6 + .col-md-6 {
  padding-left: 14px;
}

@media (min-width: $screen-md-min) {
  .account-settings.panel .panel-body .row {
    div {
      width: 30%;
    }
  }
}