.nav-wizard {
	display: block;
	list-style: none;
	padding: 0;
	position: relative;
	width: 100%;
	margin-bottom: 100px;

	> li {
		display: block;
		float: left;
		width: 25%;
		text-align: center;
		padding-left: 0;
	}

	> li::before {
		border-top: 3px solid #55606E;
		content: "";
		display: block;
		font-size: 0;
		overflow: hidden;
		position: relative;
		top: 11px;
		right: 1px;
		width: 100%;
		z-index: 1;
	}

	> li:first-child::before {
		left: 50%;
		max-width: 50%;
	}

	> li:last-child::before {
		max-width: 50%;
		width: 50%;
	}

	> li {

		> a {
			text-decoration: none;
			cursor: pointer;

			> .step {
				background: #B2B5B9;
				color: #fff;
				display: inline;
				font-size: 15px;
				font-weight: 700;
				padding: 7px 13px;
				border: 3px solid transparent;
				border-radius: 50%;
				line-height: normal;
				position: relative;
				text-align: center;
				z-index: 2;
				transition: background-color 1s, color 1s, border-color 1s;
			}

			> .title {
				color: #bfbfbf;
				display: block;
				font-size: 13px;
				line-height: 15px;
				max-width: 100%;
				position: relative;
				table-layout: fixed;
				text-align: center;
				top: 20px;
				word-wrap: break-word;
				z-index: 104;
				transition: color 1s;
			}
		}
	}

	> li.active {


		> a {
			> .step {
				background: #0091d9;
				color: #fff;
				font-weight: 700;
				padding: 7px 13px;
				font-size: 15px;
				border-radius: 50%;
				border: 3px solid #55606E;
			}

			> .title {
				color: #2B3D53;
			}

		}
	}
}