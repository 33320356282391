.navbar-side {
	&.navbar {
		transition: width 0.4s;
		transition-timing-function: ease-out;
		display: block;
		height: $navbar-side-height;
		position: fixed;
		z-index: 1000;
		width: $navbar-side-small-width;
		padding-left: 15px;
		padding-right: 15px;
		font-size: 15px;
		border: none;
		border-radius: 0;

		&.navbar-default{
			background: $navbar-deafult-gradient;
			.navbar-nav > li > a {
				color: $navbar-color;
			}
		}

		+ .page-wrapper {
			transition: width 0.4s, margin 0.4s, padding 0.4s;
			transition-timing-function: ease-out;
			margin-left: $navbar-side-small-width;
		}

		.navbar-header {
			height: 60px;
			margin-bottom: 2px;
			white-space: nowrap;
			transition: width 0.6s;
			transition-timing-function: ease-out;
			transition-delay: 0.1s;
		}

		.navbar-brand {
			padding: 14px 0px;
			color: #a11f1e;
		}

		li.active > a, .navbar-list.collapsed {
			background-color: $navbar-link-active-bg;
			color: $navbar-link-active-color;
		}

		.hide-small-nav {
			transition: width 0.4s;
			transition-timing-function: ease-out;
			width: 0;
			display: none;
			margin-left: 5px;
		}

		.navbar-nav > li {
			float: none;
			white-space: nowrap;

			> a {
				padding-left: 30px;
			}
		}

		.navbar-nav {
			transition: width 0.4s;
			transition-timing-function: ease-out;
			width: $navbar-side-small-width;
			margin: 0 -15px;
			box-shadow: none;
			position: relative;
			border: none;
			background-color: inherit;
		}


		.navbar-list {
			width: 100%;
			padding: 0;
			margin: 0;
			list-style: none;
			font-size: 14px;
			text-align: left;
			background-clip: padding-box;
		}

		.nav > li > a:not(.dropdown), .navbar-list > li > a {
			&:hover {
				color: $navbar-link-active-color;
				background-color: $navbar-link-active-bg;
			}
		}

		.navbar-list > li > a {
			text-decoration: none;
			display: block;
			padding: 5px 50px;
			line-height: 1.42857;
			white-space: nowrap;
			color: $navbar-color;

		}

		.navbar-list > li.active > a {
			background-color: $navbar-link-active-bg;
			color: $navbar-link-active-color;
		}

		.collapsing {
			transition: height 0.35s, visibility 0.35s;
			transition-timing-function: ease-out;
			margin-left: -1px;
		}

		.navbar-list-toggle:before, .navbar-nav > li > a:before {
			content: "";
			border-left: 4px solid $side-border-color;
			margin-left: -35px;
			margin-right: 30px;
			padding-bottom: 13px;
			padding-top: 13px;
			transition: margin 0.35s;
			transition-timing-function: ease-out;
		}

		.navbar-list-toggle:not(.collapsed):before, .navbar-nav > li.active > a:before {
			margin-left: -33px;
		}

		.navbar-list-toggle, .navbar-nav > li > a {
			transition: margin 0.35s;
			transition-timing-function: ease-out;
		}

		.navbar-list-toggle:not(.collapsed), .navbar-nav > li.active > a {
			margin-left: 3px;
			background-color: $navbar-link-active-bg;
			color: $navbar-link-active-color;
		}

		.collapse.in {
			display: flex;
			margin-left: 4px;
			transition: margin 0.35s;
			transition-timing-function: ease-out;
			margin-top: -1px;
		}

		&:not(.in).collapse {

			font-size: 20px;

			.collapse, .collapsing {
				position: absolute;
			}

			.navbar-list.popup-dropdown {
				position: relative;
				top: -50px;
				left: $navbar-side-small-width - 5px;
				padding: 5px 5px 5px 2px;
				background-color: $navbar-base;
				box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
				border-radius: 0 2px 2px 0;

				> li > a {
					padding: 5px 10px;
					font-size: 13px;
				}

			}
		}

		&.in {
			width: $navbar-side-width;
			overflow-x: hidden;

			+ .page-wrapper {
				margin-left: $navbar-side-width;
			}

			.navbar-list-toggle:not(.collapsed) {
				transition-delay: 0.43s;
			}

			@media (min-width: $grid-float-breakpoint) {
				.navbar-list-toggle:before, .navbar-nav > li > a:before {
					padding-bottom: 16px;
					padding-top: 15.5px;
				}
				.collapse.in:before, .collapsing:before {
					content: "";
					border-left: 4px solid $side-border-color;
					margin-left: -4px;
				}
				.collapsing:before {
					margin-left: -3px;
					height: 100%;
					position: absolute;
					padding-bottom: 16px;
					padding-top: 15.5px;
				}
			}

			.collapse.in, .collapsing {
				background-color: $navbar-link-active-bg;
			}

			.hide-small-nav {
				display: inline;
			}

			.navbar-nav, .navbar-header, .divider {
				width: $navbar-side-width;
			}

			+ .page-wrapper {
				margin-left: $navbar-side-width;
			}
		}

		&.collapsing {
			width: ($navbar-side-small-width+$navbar-side-width) / 2;
			transition-timing-function: ease-in;
			overflow-x: hidden;

			+ .page-wrapper {
				margin-left: ($navbar-side-small-width+$navbar-side-width) / 2;
				transition-timing-function: ease-in;
			}

			@media (min-width: $grid-float-breakpoint) {
				.navbar-list-toggle:before, .navbar-nav > li > a:before {
					padding-bottom: 16px;
					padding-top: 15px;
				}
				.collapse.in:before, .collapsing:before {
					content: "";
					border-left: 4px solid $side-border-color;
					margin-left: -4px;
				}
			}

			.collapse.in, .collapsing {
				background-color: $navbar-link-active-bg;
			}

			.hide-small-nav {
				width: ($navbar-side-small-width+$navbar-side-width) / 2 - 100px;
				transition-timing-function: ease-in;
				display: inline;
			}

			.navbar-nav {
				width: ($navbar-side-small-width+$navbar-side-width) / 2;
				transition-timing-function: ease-in;
			}

			.navbar-header {
				width: $navbar-side-width/2;
			}

			.navbar-form .form-group {
				display: block;
			}

		}

		@media (max-width: $grid-float-breakpoint) {

			width: 0px;
			height: 0px;

			+ .page-wrapper {
				margin-left: 0px;
			}

			.navbar-header {
				display: none;
			}
			.navbar-nav {
				display: none;
				width: 0;

				> li {
					width: 0;
				}
			}
			.navbar-form {
				display: none;
			}

			.form-control {
				width: 0;
			}

			.btn {
				width: 0;
				transition: width 0.4s;
				transition-timing-function: ease-out;
			}

			&:not(.in) {
				padding: 0;
			}

			&.collapse.in {
				overflow-x: visible;
				width: $navbar-side-small-width;

				font-size: 20px;

				+ .page-wrapper {
					margin-left: $navbar-side-small-width;
				}

				.collapse, .collapsing {
					position: absolute;
					background-color: transparent;
				}

				.navbar-list-toggle:before, .navbar-nav > li > a:before {
					padding-bottom: 9px;
					padding-top: 9px;
				}

				.hide-small-nav {
					display: none;
				}

				.navbar-header {
					display: block;
					width: $navbar-side-small-width;
				}

				.navbar-brand {
					max-width: 100%;
					overflow-x: hidden;
				}

				.navbar-nav {
					width: $navbar-side-small-width;
					display: block;

					> li {
						width: $navbar-side-small-width - 2px;
						max-width: 100%;
						overflow-x: hidden;
					}
				}

				.navbar-form {
					display: block;
					margin-left: -25px;
				}

				.form-control {
					color: #00b3ee;
					width: $navbar-side-small-width - 10px;
				}

				.btn {
					width: $navbar-side-small-width - 10px;
				}

				.navbar-list.popup-dropdown {
					position: relative;
					top: -50px;
					left: $navbar-side-small-width - 5px;
					padding: 5px 5px 5px 2px;
					background-color: $navbar-base;
					box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
					border-radius: 0 2px 2px 0;

					> li > a {
						padding: 5px 10px;
						font-size: 13px;
					}

				}

				.divider {
					width: $navbar-side-small-width - 2px;
				}

			}

			&.collapsing {
				width: $navbar-side-small-width / 2;
				overflow-x: hidden;
				font-size: 20px;

				+ .page-wrapper {
					margin-left: ($navbar-side-small-width) / 2;
				}

				.navbar-list.popup-dropdown {
					position: relative;
					top: -50px;
					left: $navbar-side-small-width - 5px;
					padding: 5px 5px 5px 2px;
					background-color: $navbar-base;
					box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
					border-radius: 0 2px 2px 0;

					> li > a {
						padding: 5px 10px;
						font-size: 13px;
					}

				}

				.navbar-nav > li {
					> a {
						padding-left: 30px;
						padding-right: 50px;
					}
				}

				.collapse.in, .collapsing {
					position: absolute;
					background-color: transparent;
				}

				.navbar-list-toggle:before, .navbar-nav > li > a:before {
					padding-bottom: 9px;
					padding-top: 9px;
				}

				.hide-small-nav {
					display: none;
				}

				.navbar-brand {
					margin-left: 15px;
				}

				.navbar-nav {
					width: ($navbar-side-small-width) / 4;
					display: block;

					margin-left: 0;

					li {
					}
				}

				.navbar-header {
					display: block;
					width: ($navbar-side-small-width) / 4;
				}

			}

		}
	}
}
