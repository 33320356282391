.footer {
	bottom: 0;
	width: 100%;
	background-color: $brand-primary;
	color: white;
	padding: 10px;

	a {
		color: white;
	}

	a:hover {
		color: $link-hover-color;
	}
}